.custom-input-style::placeholder {
    color: #6f6f6f; /* Adjust the color to your preferred shade of gray */
  }
  
  .custom-input-style {
    height: 38px;
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: border-color 0.3s;
  }
  
  .custom-input-style:hover {
    border-color: #40a9ff;
  }

  
  .full-width-button {
    width: 100%;
    margin: 10px 0;
  }

  .form-title {
    font-size: 16px; /* Adjust the font size as needed */
    font-weight: bold; /* Make it bold */
    color: #333; /* Choose a suitable color */
    margin-bottom: 8px; /* Space between the title and the input field */
  }
  
  