.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.custom-input-style::placeholder {
  color: #6f6f6f; /* Adjust the color to your preferred shade of gray */
}

.custom-input-style {
  height: 40px;
  padding: 6px 12px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  transition: all 0.3s ease;
}



.custom-input-style .ant-picker-input input::placeholder {
  color: #6f6f6f; /* Your desired gray color */
}

/* Enhanced input and select styles */
.custom-input-style {
height: 40px; /* Slightly larger for better interaction */
padding: 6px 12px;
border-radius: 6px; /* More pronounced rounded corners */
border: 1px solid #d9d9d9;
transition: all 0.3s ease;
}

/* Button Styles */
.custom-button {
  background-color: #1890ff;
  color: white;
  border-radius: 6px;
  border: none;
  padding: 6px 15px;
  margin-right: 8px;
}
.custom-button.reset {
  background-color: #f5222d;
}


.custom-button:hover {
opacity: 0.85; /* Slight opacity change on hover */
}

@media (max-width: 768px) {
  .ant-row {
    flex-direction: column;
  }
  .ant-col {
    width: 100%;
    margin-bottom: 10px;
  }
  .custom-button {
    width: 100%;
    margin-bottom: 10px;
  }
}